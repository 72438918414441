import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("filter-drawer-composer", {
    attrs: {
      "has-filter-inactive": false
    }
  }, [_c("select-filter", {
    key: "ProduceSize",
    attrs: {
      "label": "Produce Sizes",
      "reference": "grower.common.produce-sizes",
      "source": "code",
      "source-label": _vm.genProduceSizesLabel
    }
  }), _c("operator-filter", {
    key: "CurrentWeight",
    attrs: {
      "label": "Current Weight",
      "type": "number"
    }
  }), _c("operator-filter", {
    key: "WeightCap",
    attrs: {
      "label": "Weight Cap",
      "type": "number"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "FilterBunkerProcessing",
  methods: {
    genProduceSizesLabel(option) {
      return `${option.code} ${option.description}`;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FilterBunkerProcessing = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "filter-bunker"
  }, [_c("resource", {
    attrs: {
      "name": "grower.common.produce-sizes",
      "api-url": _vm.apiUrl,
      "resource-id-name": "code"
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.bunkers",
      "api-url": _vm.apiUrl,
      "redirect-route": "/watties-grower/bunker-processing"
    }
  }, [_c("filter-bunker-processing")], 1)], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    FilterBunkerProcessing
  },
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#"
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
